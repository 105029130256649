body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-shape-divider-bottom-1651714942 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1651714942 svg {
  position: relative;
  display: block;
  width: calc(121% + 1.3px);
  height: 133px;
}

.custom-shape-divider-bottom-1651714942 .shape-fill {
  fill: #121212;
}

@media only screen and (max-width: 600px) {
  .custom-shape-divider-bottom-1651714942 svg {
    position: relative;
    display: block;
    width: calc(121% + 1.3px);
    height: 73px;
  }
}

